// #Links
a, a:visited {
  color: $link_color;
  text-decoration: $link_decoration;
}

a:hover, a:focus {
  color: $link_hover_color;
  text-decoration: $link_hover_decoration;
  outline: $link_outline;
}

p a, p a:visited {
  line-height: inherit;
}
