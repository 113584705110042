body {
  background: #00182b;
  font: 14px/21px $body_font_family;
  color: #444;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

hr {
  border: $hr_border;
  border-width: $hr_border_width;
  clear: both;
  margin: $hr_margin;
  height: 0;
}



#header{
	position:fixed;
	width:100%;
	background-color:#fff;
	@include box-shadow(0px, 3px, 10px, rgba(50, 50, 50, 0.75)); 
	z-index:9999;
	.logo{
		float:left;
		padding:13px 0;
	}	
	.nav{
		position:absolute;
		bottom:13px;
		right:0;
		font-family: "Open Sans", sans-serif;
		font-weight:300;
		ul{
			margin:0;
			padding:0; 
		}
		li{
			padding: 0 15px;
			display:inline;
			a{
				
			}
			&.current-menu-item{
				a{
					color:#000;
				}
			}
			&:last-child{
				padding-right:0;
			}
		}
		a{
			color:#009639;
			text-transform: uppercase;
			letter-spacing: .2em;
			&:hover{
				color: #666;
			}
			&.active{
				color:#000;
			}
		}
	}
}

.hero-container{
	min-height:600px;
	padding-top:150px;
	position: relative;
	@include box-shadow(0px, 3px, 10px, rgba(50, 50, 50, 0.75)); 

}
.hero-text-container{
	width:100%;
	background-color:rgba(0, 145, 55, .55);
	margin-top:500px;
	padding:50px 0;
	position: absolute;
	bottom:0;
	font-family: "Open Sans", sans-serif;
	font-weight:300;
	font-size:36px;
	line-height:40px;
	text-transform: uppercase;
	color:#fff;
 }
 
 .services-container{
	 padding:145px 0 60px;
	 background-image:url(../images/BoomBKGD1.gif);
	 background-position: center top;
	 background-repeat: no-repeat;
	 background-size:contain;
	 background-color: rgb(0,31,67);
	 color:#fff;
	 h2{
	 	display:inline-block;
	 	font-size:16px;
	 	line-height:normal;
	 	letter-spacing:.2em;
	 	color:rgb(0,150,57);
	 }
	 h3{
		 margin-top:20px;
		 color:#fff;
		 font-size:23px;
		 line-height:25px;
	 }
	 h4{
		 margin-top:20px;
		 color:#fff;
		 font-size:23px;
		 line-height:25px;
	 }
	p{
		font-weight:normal;
		a{
			color:rgb(0,150,57);
		}
	}
	ul{
		margin:0;
		padding:0 0 0 15px;
	}
	li{
		list-style-type: disc;
	}
	 .full-width{
		 &:first-child{
			p{
				font-size:30px;
				font-family: "Open Sans", sans-serif;
				font-weight:300;
				line-height:36px !important;
			}
		 }
	 }
 }
 .products-container{
	@extend .services-container;	 
	min-height:600px;
	padding:145px 0 60px;
	background-image:url(../images/BoomBKGD2.gif);
	background-position: center top;
	background-repeat: no-repeat;
	background-size:contain;
	background-color:#fff;
	h2{
		float:left;
		width:100px;
		color:rgb(0,31,67);
	}
	.project-filters{
		float:left;
		width:500px;
		padding-left:50px;
		li{
			display:inline;
			margin-right:5px;
			list-style-type:none;
			text-transform: uppercase;
			cursor: pointer;
			color:rgb(0,31,67);
			span{
				padding:10px;
			}
			&.active{
				span{
					padding:10px;
					background-color:rgba(0,31,67, 1);
					color:#fff;
					
				}
			}
		}
	}
 }
.about-container{
	@extend .services-container;
	background-image: url(../images/BoomBKGD3.gif);
	background-position: center top;
	background-repeat: no-repeat;
	background-size:contain;

}
.contact-container{
	@extend .services-container;
	background-image: url(../images/BoomBKGD4.gif);
	background-position: center top;
	background-repeat: no-repeat;
	background-size:contain;
	background-color:#fff;
	color:#003a5d;
	h3{
		color:#003a5d;
		
	}
	form{
		margin-top:28px;
	}
	.wpcf7{
		input{
			float:left;
			margin-bottom:5px;
			background-color:#96d4ad;
			border:0;
			color:#fff;
			@include placeholder {
			    color: #fff;
			}
		}
		br{
			display:none;
		}
		.full{
			width:98%;
		}
		.email{
			input{
				width:55%;
				margin-right:5px;
			}
		}
		.tel-526{
			input{
				width:40%;
			}
		}
		textarea{
			background-color:#96d4ad;
			border:0;
			color:#fff;
			@include placeholder {
			    color: #fff;
			}
		}
		.wpcf7-submit{
			margin:0;
			padding:0;
			background:none;
			color:rgba(0,150,57, 1);
			text-shadow:none;
			font-size:16px;
			text-transform: uppercase;
		}		
		.wpcf7-validation-errors{
			margin:0;
			padding:10px;
			border:0;
			background-color: rgba(249, 59, 59, 0.66);
		}
		.wpcf7-mail-sent-ok{
			margin:0;
			padding:10px;
			border:0;
			background-color: rgba(115, 252, 140, 0.66)			
		}
		.wpcf7-not-valid-tip{
			display:none;
		}
		.wpcf7-form-control-wrap{
			position:inherit;	
		}
		.wpcf7-not-valid{
			background-color:rgba(249, 59, 59, 0.66);
		}		
		.wpcf7-form-control-wrap {
			&.message{
				float:left;
				width:100%;
			}
		}
	}
}

// Products

#projects{
	margin-top:30px;
	width:100%;
	text-align:center;
	h2{
		float:none;
	}
	.mix{
	    display: none;
		width: 33.3%;
		float:left;	    
	}
	.project-box{
		height:300px;
		margin:2px;
		background-color:rgba(55,61,73,1);
	}
	
	.project-overlay{
		position:relative;
		height:300px;
		box-sizing: border-box;
		overflow: hidden;
		&:hover{
			border:4px solid rgba(55,61,73,1);
		}
		a{
			position:absolute;
			height:100px;
			width:100%;
			display:block;
			padding:20px 10px 0;
			bottom:-230px;
			color:#fff;
			text-align:left;
			font-size:36px;
			line-height:38px;
			font-weight:300;
			text-transform: uppercase;
			background-color:rgba(55,61,73,.75);
			span{
				display:block;
				font-size:16px;
				color:#009639;
			}
		}
	}
	
}



// #Single Templates

.staff-container{
	@extend .services-container;
	padding:300px 0 60px;
	background-image:url(../images/BoomBKGD2.gif);
	background-position:center 150px;
	background-repeat:no-repeat;
	background-color:#fff;
	h1{
		font-size:24px;
		line-height:26px;
		color:#003a5d;
		span{
			font-style:italic;			
			font-weight:300;
		}
		
	}
	h2{
		margin-bottom:40px;
	}
	p{
		color:#003a5d		
	}
}

.single-product-container{
	@extend .staff-container;
	background-image:url(../images/BoomBKGD2.gif);
	background-position:center 150px;
	background-repeat:no-repeat;
	background-color:#fff;
	h3{
		 color:rgb(0,31,67);		
	}
}